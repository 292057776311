import { Badge, Box, IconButton } from "@mui/material";
import useShoppingCartContext from "../hooks/useShoppingCartContext";
import useRouter from "../routes/useRouter";
import { appPath } from "../layouts/config";
import useResponsive from "../hooks/useResponsive";

interface Props {
    sx?: any;
}

export default function ShoppingCartBadge({ sx, ...others }: Props) {
    const router=useRouter();
    const {shoppingCart} = useShoppingCartContext();
    
    const {isSmallScreen} = useResponsive();

    const handleClick=()=>{
        router.push(appPath.shoppingCart);
    }

    return (
        shoppingCart.length !== 0 ? (
            <Box
                sx={{ ...sx }}
                {...others}
                borderRadius="50%"
                bgcolor="#FFF"
                width={isSmallScreen?48:64}
                height={isSmallScreen?48:64}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Badge
                    badgeContent={shoppingCart.length}
                    color={'error'}
                    showZero={false}
                >
                    <IconButton
                        onClick={handleClick}
                    >
                        <img src="/assets/icons/blue_shopping-cart.svg" alt="blue_shopping-cart icon" width={isSmallScreen?24:32} />
                    </IconButton>
                </Badge>
            </Box>
        ) : (
            <></>
        )
    );
}
