import './App.css';
import ThemeProvider from './theme';
import Router from './routes/Router';
import ShoppingCartProvider from './context/ShoppingCart-Context';


function App() {
  return (
    <ThemeProvider>
        <ShoppingCartProvider>
          <Router/>
        </ShoppingCartProvider>
    </ThemeProvider>
  );
}

export default App;
