import { CircularProgress, Stack } from "@mui/material";

export default function LoadingComponent(){
    return (
    <Stack 
        direction={'row'}
        sx={{
            minHeight:'75vh'
        }}
        alignSelf={'center'}
        alignItems={'center'}
    >
        <CircularProgress color="primary" />
    </Stack>
    );
}