import { Navigate, Outlet, useRoutes } from "react-router-dom";
import IndexPage from "../layouts";
import { appPath } from "../layouts/config";
import { Suspense, lazy } from "react";
import LoadingComponent from "../components/LoadingComponent";

const HomePage = lazy(() => import("../pages/Index"));
const SignIn = lazy(() => import("../pages/SignIn"));
const SignUp = lazy(() => import("../pages/SignUp"));
const RegisterCard = lazy(() => import("../pages/RegisterCard"));
const Page404 = lazy(() => import("../pages/page404"));
const ShopPage = lazy(() => import("../pages/Shop"));
const ShoppingCartPage = lazy(() => import("../pages/shoppingCart"));

export default function Router(){
    const routes = useRoutes([
        {
            element : (
                <IndexPage>
                    <Suspense fallback={<LoadingComponent />}>
                        <Outlet/>
                    </Suspense>
                </IndexPage>
            ),
            children:[
                { element: <HomePage/> , index:true},
                { element: <SignIn/> ,path: appPath.signIn},
                { element: <SignUp/> ,path: appPath.signUp },
                { element: <RegisterCard /> ,path:appPath.simActivation },
                { element: <ShopPage /> , path: appPath.shop },
                { element: <ShoppingCartPage /> , path: appPath.shoppingCart },

                { element: <Page404 />,path: appPath.notFound},
            ]
        },
        {
            path: "*",
            element: <Navigate to={appPath.notFound} replace />,
        },
    ]);

    return routes;
}
