import React from "react";
import { Typography, Box } from "@mui/material";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Mettre à jour l'état pour afficher le message d'erreur
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Vous pouvez également enregistrer l'erreur dans un service de reporting d'erreurs
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Afficher un message d'erreur plus élaboré avec MUI
            return (
                <Box
                    sx={{
                        padding: 2,
                        textAlign: "center",
                        backgroundColor: "error.main",
                        color: "error.contrastText"
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        Oops! Something went wrong.
                    </Typography>
                    <Typography variant="body1">
                        We apologize for the inconvenience. Our team has been notified and we are working to fix the issue.
                    </Typography>
                    <Typography variant="body1">
                        In the meantime, you can try refreshing the page or come back later.
                    </Typography>
                </Box>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
