import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, useTheme } from "@mui/material";
import { navConfig } from "./config";
import NavItem from "../components/nav/NavItem";
import useRouter from "../routes/useRouter";
import Logo from "../components/Logo";

interface Props{
    isSmallScreen?:boolean
    openDrawer?:boolean
    closeDrawer?:(...args:any[])=>void
}

export default function Nav({isSmallScreen,openDrawer,closeDrawer}:Props){
    const theme=useTheme();
    const router=useRouter();

    const drawerList = (
        <Box sx={{ width: "250px",height:"100%",backgroundColor:'primary.main' }} role="presentation" onClick={closeDrawer}>
          <Stack py={2}>
            <Logo />
          </Stack>
          <List>
            {navConfig.map((item, index) => (
              <ListItem  key={`${index}_${item}`} disablePadding>
                <ListItemButton
                    divider
                    onClick={()=>router.push(item.path)}
                >
                  <ListItemIcon>
                      <img src={item.icon} alt={`${item.title} icon`} />
                  </ListItemIcon>
                  <ListItemText primary={item.title} sx={{color:'common.white'}}/>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
    );

    return (
        isSmallScreen?
            <Drawer open={openDrawer} onClose={closeDrawer}>
                {drawerList}
            </Drawer>
        :
        
        <Stack direction={'row'} spacing={2}>
            {navConfig.map((item,index)=><NavItem
                key={`${index}_${item}`}
                icon={<img src={item.icon} alt={`${item.title} icon`} />}
                title={item.title}
                description={item.description}
                path={item.path}
            />)}
        </Stack>
    );
}