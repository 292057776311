import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";

export default function useResponsive(){
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return {
        isSmallScreen:isSmallScreen
    };
}