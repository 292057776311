export enum appPath { 
    index='/',
    simActivation='/simActivation',
    signIn='/signIn',
    signUp='/signUp',
    shoppingCart='/shoppingCart',
    shop='/shop',
    account='/account',
    notFound='/404'
}  

export const navConfig:Array<{icon:string,title:string,description:string,path:string}>=[
    {
        icon:'/assets/icons/light_store.svg',
        title:'SHOP',
        description:'Shop Products',
        path:appPath.shop
    },
    {
        icon:'/assets/icons/light_simcard.svg',
        title:'ACTIVATE SIM',
        description:'Get sim activated',
        path:appPath.simActivation
    },
    {
        icon:'/assets/icons/light_shopping-cart.svg',
        title:'SHOPPING CART',
        description:'View items in cart',
        path:appPath.shoppingCart
    },
    {
        icon:'/assets/icons/light_account.svg',
        title:'MY ACCOUNT',
        description:'Account activities',
        path:appPath.account
    }
]

export const footerNavConfig:Array<{title:string,description:string,path:string}>=[
    
    {
        title:'MY ACCOUNT',
        description:'Account activities',
        path:appPath.account
    },
    {
        title:'SHOP',
        description:'Shop Products',
        path:appPath.shop
    },
    
    {
        title:'SHOPPING CART',
        description:'View items in cart',
        path:appPath.shoppingCart
    },

    {
        title:'ACTIVATE SIM',
        description:'Get sim activated',
        path:appPath.simActivation
    },
   
    {
        title:'Sign In',
        description:'/signIn',
        path:appPath.signIn
    },
    {
        title:'Sign Up',
        description:'/signUp',
        path:appPath.signUp
    }
]