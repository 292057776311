import { Box } from "@mui/material";
import { ReactNode } from "react";

interface Prop{
    children:ReactNode,
    sx?:any
}

export default function Main({children,sx,...others}:Prop){
    return  (
        <Box
            component="main"
            sx={{
                minHeight:'75vh',
                display:'flex',
                flexDirection:'column',
                ...sx
            }}
            {...others}
        >
            {children}
        </Box>
    );
}