import { Divider, Stack, Typography, alpha, useTheme } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

interface Prop {
  icon: React.ReactNode;
  title: string;
  description: string;
  path: string;
}

export default function NavItem({ icon, title, description, path }: Prop) {
  const theme = useTheme();

  const hoverStyles = {
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.2),
      cursor:'pointer'
    },
  };

  const activeStyles = {
    backgroundColor: alpha(theme.palette.common.white, 0.2),
  };

  return (
    <NavLink to={path} style={{textDecoration:'none'}}>
      {({isActive})=>(
        <Stack 
          sx={{
            position: 'relative',
            ...hoverStyles,
            ...(isActive ? activeStyles : {})
          }}
        >
          <Stack spacing={1} justifyContent="center" sx={{ p: 2 }}>
            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
              {icon}
              <Typography color={theme.palette.common.white}>{title}</Typography>
            </Stack>
            <Stack direction="row" alignSelf="center">
              <Typography color="grey">{description}</Typography>
            </Stack>
          </Stack>
          {isActive && <Divider color={theme.palette.common.white} sx={{ py: 0.2, position: 'absolute', bottom: 0, width: '100%' }} />}
        </Stack>
      )}
    </NavLink>
  );
}
