import { IconButton, Link, Stack, useTheme } from "@mui/material";
import Logo from "../components/Logo";
import Nav from "./Nav";
import { appPath } from "./config";
import { useState } from "react";
import ErrorBoundary from "../components/ErrorBundary";
import useResponsive from "../hooks/useResponsive";


export default function Header(){
    const theme=useTheme();
    const {isSmallScreen} = useResponsive();
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleDrawerOpen=()=>{
        setOpenDrawer(true);
    }

    return (
    isSmallScreen?
    <Stack
        sx={{
            backgroundColor:theme.palette.primary.main,
            minheight:0.15,
            p:2
        }}
    >
        <Stack
            direction={'row'}
        >
            <Logo style={{height:'48px'}} />
        </Stack>
        <Stack
            direction={'row'}
            justifyContent={'space-between'}
        >

        <IconButton
            onClick={handleDrawerOpen}
        >
            <img src="/assets/icons/menu.svg" alt="logo" height={'24px'}/>
        </IconButton>
        <ErrorBoundary>
            <Nav  isSmallScreen={isSmallScreen} openDrawer={openDrawer} closeDrawer={()=>setOpenDrawer(false)}/>
        </ErrorBoundary>
        <Stack
            direction={'row'}
            justifyContent={'end'}            
            spacing={2}
            pt={1}
        >
             <Link 
                color={theme.palette.common.white}
                underline={'hover'}
                href={appPath.signIn}
                
            >
                Sign In
            </Link>
            <Link 
                color={theme.palette.common.white}
                underline={'hover'}
                href={appPath.signUp}
            >
                Sign Up
            </Link>
        </Stack>

        </Stack>
    </Stack>

    :
    <Stack 
        justifyContent={'flex-end'}
        sx={{
            backgroundColor:theme.palette.primary.main,
            minheight:0.15,
            px:'100px',
        }}
    >
        <Stack
            direction={'row'}
            justifyContent={'end'}            
            spacing={2}
            pt={1}
        >
             <Link 
                color={theme.palette.common.white}
                underline={'hover'}
                href={appPath.signIn}
            >
                Sign In
            </Link>
            <Link 
                color={theme.palette.common.white}
                underline={'hover'}
                href={appPath.signUp}
            >
                Sign Up
            </Link>
        </Stack>

        {/* <Divider sx={{mt:2,py:0.001}} color={theme.palette.grey[500]}/> */}

        <Stack
            id='menu'
            direction={'row'}
            justifyContent={'space-between'}
        >
            <Stack alignItems={'center'} alignSelf={'center'} p={1}>
                <Logo/>
            </Stack>
            <Stack justifyContent={'flex-end'}>
                <ErrorBoundary>
                    <Nav />
                </ErrorBoundary>
            </Stack>
        </Stack>
    </Stack>
    );
}