import {Divider, Link, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Logo from "../components/Logo";
import { footerNavConfig } from "./config";


export default function Footer(){
    const theme=useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const socialIconsStyle={
        height:'20px',
        width:'20px'
    };

    return (
        <Stack

            justifyContent={'flex-start'}

            sx={{
                px:'100px',
                backgroundColor:theme.palette.primary.main,
                height:0.30,

                [theme.breakpoints.down('md')]:{px:"15px"}
            }}

        >
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                sx={{py:2}}
            >
                { !isSmallScreen  &&
                    <Stack alignItems={'center'} alignSelf={'center'}>
                        <Logo/>
                    </Stack>
                }
                <Stack direction={isSmallScreen?'column':'row'} alignSelf={'center'} spacing={2}>
                    {footerNavConfig.map((item,index)=>
                        <Link 
                            key={`${index}-${item.title}`}
                            color={theme.palette.common.white}
                            underline={'hover'}
                            href={item.path}
                        >
                            {item.title}
                        </Link>
                    )}
                </Stack>
                <Stack justifyContent={'space-evenly'}>
                    { isSmallScreen && <Logo style={{height:'48px'}} />}
                    <Stack direction={'row'} alignSelf={'center'} spacing={1}>
                        <img src="/assets/icons/Social Network/Facebook.svg" alt="facebook svg icon" style={socialIconsStyle}/>
                        <img src="/assets/icons/Social Network/Instagram.svg" alt="instagram svg icon" style={socialIconsStyle}/>
                        <img src="/assets/icons/Social Network/Twitter.svg" alt="twitter svg icon" style={socialIconsStyle}/>
                    </Stack>
                </Stack>
            </Stack>

            <Divider sx={{ my:2 ,py:0.005}} color={theme.palette.grey[500]}/>

            <Stack direction={'row'} alignSelf={'center'} spacing={2} pb={5}>
                    <Typography color={theme.palette.grey[500]}>©2024Camtel Blue</Typography>
                    <Link 
                        color={theme.palette.grey[500]}
                        underline={'hover'}
                        href={'#'}
                    >
                        Privacy policy
                    </Link>
                    <Link 
                        color={theme.palette.grey[500]}
                        underline={'hover'}
                        href={'#'}
                    >
                        Term of service
                    </Link>
            </Stack>

        </Stack>
    );
}