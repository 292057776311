import { createContext, ReactNode, useEffect, useState } from "react";
import { Subscription } from "../entities/interfaces/Subscription";
import Cookies from 'js-cookie';
import { Save } from "@mui/icons-material";



const initialShoppingCart: Subscription[] = [];

export interface ShoppingCartContextType {
    shoppingCart: Subscription[];
    addItemToCart: (item: Subscription) => void;
    removeItemFromCart: (item: Subscription) => void;
    clearCart: () => void;
}

export const ShoppingCartContext = createContext<ShoppingCartContextType>({
    shoppingCart: initialShoppingCart,
    addItemToCart: () => {},
    removeItemFromCart: () => {},
    clearCart: () => {}
});

interface Props {
    children: ReactNode;
}

export default function ShoppingCartProvider({ children }: Props) {
    const [shoppingCart, setShoppingCart] = useState<Array<Subscription>>(initialShoppingCart);

    const updateCookie = (cart: Subscription[]) => {
        Cookies.set('shoppingCart', JSON.stringify(cart), { expires: 7 }); 
    };

    const addItemToCart = (item: Subscription) => {
        setShoppingCart((prevCart) => {
            const newCart = [...prevCart, item];
            updateCookie(newCart);
            return newCart;
        });
    };

    const removeItemFromCart = (item: Subscription) => {
        setShoppingCart((prevCart) => {
            const newCart = prevCart.filter((cartItem) => cartItem !== item);
            updateCookie(newCart);
            return newCart;
        });
    };

    const clearCart = () => {
        setShoppingCart([]);
        updateCookie([]);
    };

    useEffect(() => {
        let savedCart:Subscription[]

        try{
            savedCart = JSON.parse(Cookies.get('shoppingCart')!) as Subscription[];
        }catch(e){
            savedCart = [];
        }

        let updatedCart:Subscription[] = [];

        for (let index = 0; index < savedCart.length; index++) {
            const element = savedCart[index];
            if(element.phoneNumber){
                updatedCart.push(element);
            }
        }

        if (updatedCart) {
            setShoppingCart(updatedCart);
        }
    }, []);

    return (
        <ShoppingCartContext.Provider value={{ shoppingCart, addItemToCart, removeItemFromCart, clearCart }}>
            {children}
        </ShoppingCartContext.Provider>
    );
}
