import { ReactNode } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Main from "./Main";
import { Box } from "@mui/material";
import ShoppingCartBadge from "../components/shoppingCartBadge";
import ErrorBoundary from "../components/ErrorBundary";

interface Prop{
    children:ReactNode
}
export default function IndexPage({children}:Prop){
    return (
        <Box
            sx={{
                position:'relative'
            }}
        >
            <Header />
            <Main>
                {children}
            </Main>
            <ErrorBoundary>
                <ShoppingCartBadge 
                        sx={{
                            position:'fixed',
                            right:"20px",
                            bottom:'50vh'
                        }}
                    />
            </ErrorBoundary>
            <Footer />
        </Box>
    );
}